/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, YouTubeAsBackground, Image, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--80 pt--80" name={"ako-bezpecne-zavesit-hodiny"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ako bezbečne zavesiť hodiny<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1d3xum7 css-6xhxy5 pb--60 pt--60" name={"criwuchgkgu"} layout={"l1"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=350x_.PNG);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=660x_.PNG);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=860x_.PNG);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=1400x_.PNG);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=2000x_.PNG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=660x_.PNG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=1400x_.PNG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/39117/c71b60fa5edb4d0794636ad4e164266d_s=3000x_.PNG);
    }
  
background-position: 44% 53%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--220" content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ot3vi6az4c9"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 1"}>
              </Title>

              <Text className="text-box text-box--center" content={"Navŕtajte diery do steny v rozteči 35mm, podľa priloženej šablóny, ktorú dáte do vodováhy a predvrtáte si diery malým vrtákom.<br>Následne diary zväčšíme na priemer 8mm a vložíme hmoždenky.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 2<br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"Zoberte dve sktrutky a zaskrutkujte,<br>tak aby hlavička bola od steny cca 3-4mm.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"q2mk4ehdh3"} layout={"l1"}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"Xj1LDGlS0us"} />
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--220" content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"mkvcrnu6two"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Krok 3"}>
              </Title>

              <Text className="text-box" content={"Skrutky by mali zapasovať na druhý zub z každej strany.<br>Od teraz si môžete užívať svoje vysnívané nové hodiny.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ya4tifgor2s"} layout={"l1"}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"0MLRK6RR0mY"} />
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--220" content={"<br>"}>
              </Title>

              <Title className="title-box fs--154" content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"8g8lvt7k9yy"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<a href=\"/starostlivost#hodiny\" style=\"text-decoration: underline;\">Starostlivosť o hodiny</a><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"lxvqjlifgjs"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"n3ktqk4xauj"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Title className="title-box" style={{"marginBottom":0}} content={"Andrej Nagy<br>"}>
              </Title>

              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}